/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql, Link } from "gatsby"
import { CgArrowLongRight } from 'react-icons/cg'
import Layout from "~/components/layout"
import Seo from "~/components/seo"
import DotsIcon from "~/images/dots.inline.svg"
import flexibleContent from "~/components/subservice/content"
import Faqs from "~/components/faqs"
import BankWithUsForm from "~/components/forms/bankWithUs"

const scrollDown = id => {
  const el = document.getElementById(id)
  const top = el.getBoundingClientRect().top + window.scrollY - 130

  window.scrollTo({
    top,
    behavior: 'smooth'
  })
}

const SubServicePage = ({ data: { page } }) => {
  //const subnav = page.subServicePage?.content?.filter(({showInNavigation }) => showInNavigation ).map(({ title, slug }) => ({ title, slug }))
  const financial = ['wealth-management', 'investment-services', 'trust-services'].some(slug => slug === page.slug)
  const subnav = page.subServicePage?.content?.reduce((acc, curr) => {
    const { showInNavigation, title, slug, card } = curr
    if (card) {
      return [...acc, ...card.filter(({ showInNavigation: showInNav }) => showInNav).map(({ title: t, slug: s }) => ({ title: t, slug: s }))]
    }
    return showInNavigation ? [...acc, { title, slug }] : acc
  }, []) || []
  return (
    <Layout
      financial={financial}>
      <Seo title={page.title} />
      <section
        sx={{
          backgroundImage: `url('${page.featuredImage?.node?.localFile?.publicURL}')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          borderBottomRightRadius: '50% 10%'
        }}
        className="pt-44 bg-primary">
        <div className="container relative mx-auto px-8">
          <div className="flex -mx-8">
            <div className="flex flex-col w-full px-8">
              <h1
                className="text-4xl md:text-5xl text-white mb-6">{page.title}</h1>
              {page.wpParent && (
                <ul
                  className="flex items-center list-none text-white mb-12 text-sm md:text-base">
                  <li
                    className="mr-3 flex-shrink-0">
                    <Link
                      to={`/${page.wpParent?.node?.slug}`}>
                      {page.wpParent?.node?.title}
                    </Link>
                  </li>
                  <li
                    className="mr-3">
                    <CgArrowLongRight />
                  </li>
                  <li
                    className="mr-3 flex-shrink-0">
                    <span>{page.title}</span>
                  </li>
                </ul>
              )}
            </div>
          </div>
          <DotsIcon
            className="absolute bottom-0 right-0 -mb-16 mr-8 w-32 opacity-60" />
        </div>
      </section>
      {!!subnav.length && (
        <section className="py-12">
          <div className="container mx-auto px-8">
            <ul className="flex flex-col md:flex-row flex-wrap -mx-8 list-none">
              {subnav.map(({ title, slug }) => (
                <li
                  key={slug}
                  className="px-8 mb-6">
                  <a
                    onClick={e => {
                      e.preventDefault()
                      scrollDown(slug)
                    }}
                    className="flex items-center text-primary pb-2 border-b border-current hover:text-secondary"
                    href={`#${slug}`}>
                    <span className="mr-2">{title}</span>
                    <CgArrowLongRight />
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </section>
      )}
      {page.content && (
        <section className="py-12">
          <div className="container mx-auto px-8">
            <div className="flex justify-center flex-wrap -mx-8">
              <div className="w-full md:w-3/4 text-center px-8">
                <div
                  dangerouslySetInnerHTML={{
                    __html: page.content
                  }} />
              </div>
            </div>
          </div>
        </section>
      )}
      {page.subServicePage?.content?.map(({ __typename, ...rest }, i) => {
        const Component = flexibleContent[__typename]
        return <Component key={`${rest.slug}-${i}`} {...rest} />
      })}
      {!!page.subServicePage?.relatedFaqs?.length && (
        <Faqs
          slug={page.slug}
          faqs={page.subServicePage.relatedFaqs} />
      )}
      {page.slug === 'banking-with-us' && (
        <section className="py-12">
          <div className="container mx-auto px-8">
            <BankWithUsForm />
          </div>
        </section>
      )}
    </Layout>
  )
}

export const query = graphql`
  query SubServiceQuery($databaseId: Int!){
    page: wpPage(databaseId: { eq: $databaseId }){
      databaseId
      slug
      title
      content
      wpParent {
        node {
          ...on WpPage {
            title
            slug
          }
        }
      }
      featuredImage {
        node {
          localFile {
            publicURL
          }
        }
      }
      subServicePage {
        content {
          __typename
          ...on WpPage_Subservicepage_Content_PictureText {
            title
            slug
            showInNavigation
            subtitle
            description
            image {
              file {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO,WEBP], placeholder: DOMINANT_COLOR, quality: 100)
                  }
                }
              }
              position
            }
            button{
              text
              url
            }
          }
          ...on WpPage_Subservicepage_Content_Cards{
            card{
              title
              showInNavigation
              slug
              subtitle
              description
            }
          }
          ... on WpPage_Subservicepage_Content_Feature {
            title
            subtitle
            description
            image {
              file {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO,WEBP], placeholder: DOMINANT_COLOR, quality: 100)
                  }
                }
              }
              position
            }
            legalese
          }
          ...on WpPage_Subservicepage_Content_Callout {
            title
            slug
            showInNavigation
            subtitle
            description
            image {
              file {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO,WEBP], placeholder: DOMINANT_COLOR, quality: 100)
                  }
                }
              }
              position
            }
            button {
              text
              url
            }
          }
          ...on WpPage_Subservicepage_Content_General {
            title
            slug
            showInNavigation
            content
          }
          ...on WpPage_Subservicepage_Content_Calculator {
            title
            slug
            showInNavigation
            code 
          }
          ...on WpPage_Subservicepage_Content_Team {
            title
            slug
            showInNavigation
            members {
              ...on WpTeamMember{
                databaseId
                title
                featuredImage {
                  node {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO,WEBP], placeholder: DOMINANT_COLOR, quality: 100)
                      } 
                    }
                  }
                }
                teamMember{
                  position
                  location
                  phone
                  email
                  mls
                }
              }
            }
          }
        }
        relatedFaqs{
          ...on WpFaq {
            databaseId
            title
            content
            faqsCategories {
              nodes{
                termTaxonomyId
                name
              }
            }
          }
        }
      }
    }
  }
`

export default SubServicePage