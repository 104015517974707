import { default as React } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const FeatureContent = ({ title = '', subtitle = '', description = '', image: { file, position = true }, legalese = '' }) => {
  return (
    <section
      className="py-24">
      <div className="container mx-auto px-8">
        <div
          className="p-12 mb-8 bg-gray-100 border-l-2 border-secondary">
          <div className="relative flex flex-wrap -mx-8">
            <div
              className={`w-full md:w-1/2 px-8 ${position ? 'order-1' : 'order-3'} relative z-10`}>
              <GatsbyImage
                className="w-full rounded-sm"
                image={getImage(file?.localFile)}
                alt={title} />
            </div>
            <div
              className="w-full md:w-1/2 px-8 order-2 flex flex-col justify-center relative z-10">
              <span
                className="text-xs font-medium uppercase text-secondary mb-4">
                {subtitle}
              </span>
              <h2
                className="text-4xl text-primary mb-8">
                {title}
              </h2>
              <div
                className="leading-loose"
                dangerouslySetInnerHTML={{
                  __html: description
                }} />
            </div>
          </div>
        </div>
        <div
          className="px-12 leading-snug">
          <small
            className="text-xs text-gray-500">
              {legalese}
          </small>
        </div>
      </div>
    </section>
  )
}

export default FeatureContent 