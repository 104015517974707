import { default as React } from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import CornerIcon from "-!svg-react-loader?props[]=className:absolute w-48 h-48 top-0 left-0 -mt-4 md:-mt-8 -ml-2 z-0!~/images/corner.inline.svg"

const PictureText = ({ title = '', slug = '', subtitle = '', description = '', image: { file, position = true }, button: { text = '', url = '' } }) => {
  const external = url && url.match(/^https?:\/\//)

  return (
    <section
      id={slug}
      className="py-16">
      <div className="container mx-auto px-8">
        <div className="relative flex flex-wrap -mx-8">
          <div
            className={`w-full md:w-5/12 px-8 mb-8 md:mb-0 ${position ? 'order-1 mr-auto' : 'order-3 ml-auto'} relative z-10`}>
            <CornerIcon />
            <GatsbyImage
              className="relative w-full rounded-sm z-10"
              image={getImage(file?.localFile)}
              alt={title} />
          </div>
          <div
            className="w-full md:w-1/2 px-8 mb-8 md:mb-0 order-2 flex flex-col justify-center relative z-10">
            <span
              className="text-xs font-medium uppercase text-secondary mb-4">
              {subtitle}
            </span>
            <h2
              className="text-5xl text-primary mb-8 whitespace-pre-line">
              {title}
            </h2>
            <div
              className="leading-loose"
              dangerouslySetInnerHTML={{
                __html: description
              }} />
            {(text && url) && (
              <div
                className="mt-16">
                {external ? (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-primary"
                    href={url}>
                    {text}
                  </a>
                ): (
                  <Link
                    className="btn btn-primary"
                    to={url}>
                    {text}
                  </Link>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default PictureText 