import {default as React} from "react"
// import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import Swiper from "~/components/swiper"
import TeamCard from "~/components/team/card"
import DotsIcon from "~/images/dots.inline.svg"

const ContentTeam = ({ slug = '', members = [] }) => {
  // const { executeRecaptcha } = useGoogleReCaptcha()
  // const [mailto, showMailto] = useState(true)

  // useEffect(() => {
  //   (async () => {
  //     const token = await executeRecaptcha("mailto")
  //     try{
  //       const data = await fetch(`/api/recaptcha`, {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json'
  //         },
  //         body: JSON.stringify({ token })
  //       }).then(response => {
  //         if(!response.ok){
  //           throw new Error('Response from API call was not Ok')
  //         }
  //         return response.json()
  //       }).then(data => data)
  
  //       if(data.score > 0.5){
  //         //safe to show mailto
  //         showMailto(true)
  //       }
  //     }catch(e){
  //       console.log(e)
  //     }
  //   })()
  // }, [executeRecaptcha]) 

  return (
    <section
      id={slug}
      className="relative py-24">
      <div
        className="absolute w-full top-0 left-0 h-2/3 bg-sand" />
      <div className="relative container mx-auto px-8 z-10">
        <div className="flex flex-col justify-center text-center mb-24">
          <h6 className="text-white">Always Here to Help</h6>
          <h2 className="text-primary text-5xl mt-2">Meet Our Team</h2>
        </div>
        <div className="flex flex-wrap -mx-8">
          <div className="relative w-full px-8">
            <DotsIcon
              className="absolute top-0 right-0 -mt-8 -m8-8 w-32" />
            <Swiper
              slidesPerView={1}
              spaceBetween={40}
              loop={false}
              breakpoints={{
                640: {
                  slidesPerView: 2
                },
                768: {
                  slidesPerView: 3
                }
              }}
              slides={members.map(member => <TeamCard key={member.databaseId} member={member} mailto={true} />)} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContentTeam