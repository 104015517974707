import { default as React } from "react"

const CalculatorContent = ({ slug = null, code = ''}) => {
  return (
    <section
      id={slug}
      className="py-12">
      <div className="container mx-auto px-8">
        <div className="flex flex-wrap -mx-8">
          <div className="w-full px-8">
            <div
              dangerouslySetInnerHTML={{
                __html: code 
              }} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default CalculatorContent