import {default as WpPage_Subservicepage_Content_PictureText} from "~/components/subservice/content/pictureText"
import {default as WpPage_Subservicepage_Content_Cards} from "~/components/subservice/content/cards"
import {default as WpPage_Subservicepage_Content_Feature} from "~/components/subservice/content/feature"
import {default as WpPage_Subservicepage_Content_Callout} from "~/components/subservice/content/callout"
import {default as WpPage_Subservicepage_Content_General} from "~/components/subservice/content/general"
import {default as WpPage_Subservicepage_Content_Calculator} from "~/components/subservice/content/calculator"
import {default as WpPage_Subservicepage_Content_Team} from "~/components/subservice/content/team"

const components = {
  WpPage_Subservicepage_Content_PictureText,
  WpPage_Subservicepage_Content_Cards,
  WpPage_Subservicepage_Content_Feature,
  WpPage_Subservicepage_Content_Callout,
  WpPage_Subservicepage_Content_General,
  WpPage_Subservicepage_Content_Calculator,
  WpPage_Subservicepage_Content_Team
}
export default components 