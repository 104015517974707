import { default as React } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"

const interests = [
  "Checking/Savings Accounts",
  "Business Banking",
  "Mortgage/Home Loans",
  "Wealth Management",
  "It’s something else!"
]

const BankWithUsForm = () => {
  const { executeRecaptcha } = useGoogleReCaptcha()

  const schema = Yup.object({
    name: Yup.string().required("First Name is Required"),
    email: Yup.string().email().required("Email is Required"),
    phone: Yup.string().required('Phone Number is required'),
    city: Yup.string().required('City is required'),
    interest: Yup.string().required('Please select what you are interested in'),
    message: Yup.string().required("Please tell leave us a message"),
  });

  const initialValues = {
    name: "",
    email: "",
    phone: "",
    city: "",
    interest: ["Checking/Savings Accounts"],
    message: "",
  }

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    isValid,
    isSubmitting,
    touched,
    errors,
  } = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (values, { resetForm }) => {
      const token = await executeRecaptcha("contact_form")
      try {
        const data = await fetch(`/api/recaptcha`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ token })
        }).then(response => {
          if (!response.ok) {
            throw new Error('Response from API call was not Ok')
          }
          return response.json()
        }).then(data => data)

        if (data.score > 0.5) {
          //safe to submit the form
          try {
            await fetch(`/api/mailer`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ id: 'bankWithUs', ...values })
            }).then(response => {
              if (!response.ok) {
                throw new Error('Could not submit form')
              }
              return response.json()
            }).then(data => data)
            //set thank you message
            resetForm()
          } catch (e) {
            console.log(e)
          }
        }
      } catch (e) {
        console.log(e)
      }
    }
  })

  console.log("Errors: /n", errors)
  return (
    <div>
      <form
        action="/"
        className="grid grid-cols-1 gap-4"
        onSubmit={handleSubmit}>
        <label
          htmlFor="name"
          className="block">
          <span className="text-primary font-heading text-xl">Full Name</span>
          <input
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            type="text"
            id="name"
            name="name"
            className={`mt-1 block w-full rounded-md py-3 px-4 ${errors.name && touched.name ? 'border-red-300' : 'border-gray-200'} placeholder-gray-300`}
            placeholder="Full Name" />
          {touched.name && errors.name ? (
            <div className="text-red-400 text-sm px-4 py-0.5">{errors.name}</div>
          ) : null}
        </label>
        <label
          htmlFor="email"
          className="block">
          <span className="text-primary font-heading text-xl">Email</span>
          <input
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            type="email"
            id="email"
            name="email"
            className={`mt-1 block w-full rounded-md py-3 px-4 ${errors.email && touched.email ? 'border-red-300' : 'border-gray-200'} placeholder-gray-300`}
            placeholder="Email" />
          {touched.email && errors.email ? (
            <div className="text-red-400 text-sm px-4 py-1">{errors.email}</div>
          ) : null}
        </label>
        <label
          htmlFor="phone"
          className="block">
          <span className="text-primary font-heading text-xl">Phone</span>
          <input
            value={values.phone}
            onChange={handleChange}
            onBlur={handleBlur}
            type="tel"
            id="phone"
            name="phone"
            className={`mt-1 block w-full rounded-md py-3 px-4 ${errors.phone && touched.phone ? 'border-red-300' : 'border-gray-200'} placeholder-gray-300`}
            placeholder="Phone" />
          {touched.phone && errors.phone ? (
            <div className="text-red-400 text-sm px-4 py-1">{errors.phone}</div>
          ) : null}
        </label>
        <label
          htmlFor="city"
          className="block">
          <span className="text-primary font-heading text-xl">City</span>
          <input
            value={values.city}
            onChange={handleChange}
            onBlur={handleBlur}
            type="text"
            id="city"
            name="city"
            className={`mt-1 block w-full rounded-md py-3 px-4 ${errors.city && touched.city ? 'border-red-300' : 'border-gray-200'} placeholder-gray-300`}
            placeholder="Full Name" />
          {touched.city && errors.city ? (
            <div className="text-red-400 text-sm px-4 py-0.5">{errors.city}</div>
          ) : null}
        </label>
        <label
          htmlFor="interest"
          className="block">
          <span className="text-primary font-heading text-xl">What Are Interested In?</span>
          <select
            name="interest"
            id="interest"
            value={values.interest}
            onChange={handleChange}
            onBlur={handleBlur}
            className={`mt-1 block w-full rounded-md py-3 px-4 ${errors.interest && touched.interest ? 'border-red-300' : 'border-gray-200'} placeholder-gray-300`}>
            {interests.map(interest => (
              <option
                key={interest}
                value={interest}>{interest}</option>
            ))}
          </select>
        </label>
        <label
          htmlFor="message"
          className="block">
          <span className="text-primary font-heading text-xl">Message</span>
          <textarea
            value={values.message}
            onChange={handleChange}
            onBlur={handleBlur}
            id="message"
            name="message"
            rows={4}
            className={`mt-1 block w-full rounded-md py-3 px-4 ${errors.message && touched.message ? 'border-red-300' : 'border-gray-200'} placeholder-gray-300`}
            placeholder="Message"></textarea>
          {touched.message && errors.message ? (
            <div className="text-red-400 text-sm px-4 py-1">{errors.message}</div>
          ) : null}
        </label>
        <div
          className="mt-4">
          <button
            disabled={!isValid || isSubmitting}
            className="btn btn-secondary"
            type="submit">Send Message</button>
        </div>
      </form>
    </div>
  )
}

export default BankWithUsForm