import { default as React, useState } from "react"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

const TeamCard = ({ mailto = false, member: { title: name = '', featuredImage: { node: { localFile } }, teamMember: { position = '', email = '', phone = '', location = '', mls } } }) => {
  const [flipped, setFlipped] = useState(false)

  return (
    <div
      onMouseEnter={e => {
        setFlipped(true)
      }}
      onMouseLeave={e => {
        setFlipped(false)
      }}
      role="presentation"
      className="relative text-center pt-8 text-gray-600 shadow-xl">
      <div
        className={`flex flex-col items-center p-8 bg-white transition-opacity ${!flipped ? 'opacity-100' : 'opacity-0'}`}>
        <GatsbyImage
          className="w-full -mt-16"
          image={getImage(localFile)}
          alt={name} />
        <h3
          className="my-4">{name}</h3>
        <em>{position}</em>
      </div>
      <div
        className="absolute inset-0 overflow-hidden">
        <div
          className={`flex flex-col h-full p-8 justify-center items-center bg-white transition-transform transform ${flipped ? 'translate-y-0' : 'translate-y-full'}`}>
          <h3
            className="my-4">{name}</h3>
          <em
            className="mb-4">{position}</em>
          {(email && mailto) ? <p className="mb-4"><a className="text-blue-400" href={`mailto:${email}`}>{ email }</a></p> : null}
          {mls && <p className="mb-4">NMLS# { mls }</p> }
          {phone && <p className="mb-4"><a className="text-blue-400" href={`tel:${phone}`}>{ phone }</a></p> }
          {location && <p className="mb-4">{ location }</p> }
        </div>
      </div>
    </div>
  )
}

export default TeamCard