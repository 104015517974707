import { default as React } from "react"

const ContentCard = ({ card = [] }) => {
  const width = 12 / card.length
  return (
    <section
      className="cards py-12">
      <div className="container mx-auto px-8">
        <div className="flex flex-wrap -mx-4">
          { card.map(({ title, slug, subtitle, description }) => (
            <div
              key={slug}
              id={slug}
              className={`w-full px-4 md:w-${width}/12`}>
              <div
                className="flex flex-col h-full px-16 py-12 bg-gray-100">
                <h6 className="text-secondary">{subtitle}</h6>
                <h3 className="text-4xl mt-2 mb-6">{title}</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: description
                  }} />
              </div> 
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default ContentCard